<template>
  <div>
    <b-sidebar
      id="history-of-articles-prod"
      :title="`${$t('articleHistory')}`"
      right
      header-class="headerModalToggle"
      shadow
      no-close-on-esc
      width="52%"
      @close="onCancelToggle"
    >
      <template #title>
        <strong style="color: #101828;">{{ `${$t('articleHistory')}` }}</strong>
      </template>
      <template
        v-slot:header-close
      >
        <button
          style="color: #98A2B3;padding-left: 0px; padding-right: 0px;;height: 60px; "
          class="fa fa-close"
          @click="onCancelToggle"
        />
      </template>
      <div class="px-2 py-2">
        <div style="width: 100%;  padding-top: 16px; margin-top: 10px; height: 74vh;">
          <table
            ref="exportable_table"
            class="team_table"
          >
            <thead>
              <tr>
                <th>
                  {{ $t('OrderNumber') }}
                </th>
                <th>
                  {{ $t('ArticleName') }}
                </th>
                <th>
                  {{ $t('State') }}
                </th>
                <th>
                  {{ $t('Time') }}
                </th>
                <th>
                  {{ $t('ExecutorName') }}
                </th>
                <th>
                  {{ $t('Notes') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="order in getOrderTrackItems"
                :key="order.id"
              >
                <td>
                  {{ order.orderNumber }}
                </td>
                <td>
                  {{ order.articleNumber }}
                </td>
                <td>
                  {{ $t(order.state) }}
                </td>
                <td>
                  {{ convertUTCDateToLoacalDate(order.created) }}
                </td>
                <td>
                  {{ order.executorName }}
                </td>
                <td>
                  {{ order.message }}
                </td>
              </tr>
            </tbody>
          </table>
          <div v-if="getTotalItemsForOrderTrackItems > 20">
            <b-pagination
              v-model="pageNumber"
              :total-rows="getTotalItemsForOrderTrackItems"
              :per-page="pageSize"
              first-number
              last-number
              align="fill"
              prev-class="prev-itemi"
              next-class="next-itemi"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
              </template>
              <template #next-text>
                <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
              </template>
            </b-pagination>
          </div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
// eslint-disable-next-line
  props: ['orderNumber', 'orderItemId'],
  data() {
    return {
      pageNumber: 1,
      pageSize: 20,
    }
  },
  computed: {
    ...mapGetters(['getOrderTrackItems', 'getTotalItemsForOrderTrackItems']),
  },
  watch: {
    pageNumber(value) {
      this.loadOrderTrackByOnAndAn({
        pageNumber: value,
        pageSize: this.pageSize,
        orderItemId: this.orderItemId,
        orderNumber: this.orderNumber,
      })
    },
  },
  methods: {
    ...mapActions(['loadOrderTrackByOnAndAn']),
    onCancelToggle() {
      this.$emit('onCancelToggle');
    },
    convertUTCDateToLoacalDate(date) {
      const utcTime = moment.utc(date).format('DD-MMM-YYYY HH:mm');
      return utcTime;
    },
  },
}
</script>

<style scoped>
.team_table td {
  padding: 16px 9px !important;
}
.next-itemi {
    padding-left: 300px !important;
}
.prev-itemi {
    padding-right: 300px !important;
}
</style>
