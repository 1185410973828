<template>
  <div>
    <b-modal
      id="add-notes-article"
      ref="modal"
      header-class="headerModal"
      centered
      :title="`${$t('addNotes')}: ${articleNumber}, ${$t('State')}: ${$t(orderState)}`"
      hide-footer
      size="md"
      @close="onCancel"
    >
      <b-form-group
        :label="$t('Description')"
      >
        <b-form-textarea
          v-model="notes"
          :placeholder="$t('Notes..')"
        />
      </b-form-group>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t('Submit') }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px;"
          @click="onCancel"
        >
          {{ $t('Cancel') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>

export default {
  props: ['orderNumber', 'orderItemId', 'articleNumber', 'orderState'],
  data() {
    return {
      notes: null,
    }
  },
  methods: {
    onSubmit() {
      const objekti = {
        notes: this.notes,
        orderItemId: this.orderItemId,
      }
      this.$emit('addNotes', objekti)
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    onCancel() {
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
      this.resetForm()
    },
    resetForm() {
      this.notes = null
    },
  },
}
</script>
